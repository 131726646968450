/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .icon {
    @apply object-contain object-right w-12 h-12 rounded-full;
  }

  .small-icon {
    @apply object-contain object-center w-10 h-10 bg-gray-700 rounded-full;
  }
}

.Polaris-DataTable__Cell,
.Polaris-Card {
  position: relative;
}

.grid .Polaris-Card + .Polaris-Card {
  margin-top: 0 !important;
}

.Polaris-Modal-Dialog__Modal {
  box-shadow: 0px 26px 80px black, 0px 0px 1px var(--p-shadow-from-dim-light);
}

.Polaris-Thumbnail {
  background-color: var(--p-surface);
}

.salvaged .Polaris-Thumbnail {
  border-color: var(--p-action-primary);
}

.options .Polaris-Thumbnail {
  border-color: var(--p-interactive);
}

.options.salvaged .Polaris-Thumbnail {
  border-image-slice: 1;
  border-image-source: linear-gradient(
    145deg,
    var(--p-action-primary),
    var(--p-interactive)
  );
}

.Polaris-Thumbnail--sizeLarge {
  width: 3.5rem;
  min-width: 3.5rem;
}

.Polaris-Layout {
  margin-top: 0;
}

.top-bar-google-play {
  height: 100%;
  padding-right: 3.2rem;
}

.top-bar-google-play img {
  height: 100%;
  object-fit: contain;
}

.Polaris-Layout__Annotation {
  flex-basis: 10rem;
}

.gearscore-input .Polaris-TextField__Backdrop {
  display: none;
}

.gearscore-input input {
  padding: 0;
  font-size: 42px;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1a1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(39, 39, 39);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 39, 39);
}

select option {
  background-color: #1a1a1a;
}

select option:checked {
  background-color: rgb(39, 39, 39);
}

.report-link {
  top: unset !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  width: unset !important;
}

.report-link a {
  color: white !important;
}

.Polaris-Collapsible {
  max-height: unset !important;
  overflow: visible;
}

:root {
  /*
  --p-action-primary: rgba(0, 128, 96, 1);
  --p-action-primary-disabled: rgba(0, 86, 64, 1);
  --p-action-primary-hovered: rgba(0, 150, 113, 1);
  --p-action-primary-pressed: rgba(0, 164, 124, 1);
  --p-action-primary-depressed: rgba(0, 179, 136, 1);
  --p-icon-on-primary: rgba(230, 255, 244, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: rgba(0, 141, 106, 1);
  --p-text-primary-hovered: rgba(0, 158, 120, 1);
  --p-text-primary-pressed: rgba(0, 176, 133, 1);
  */

  /*
  --p-action-primary: rgba(215, 149, 11, 1);
  --p-action-primary-disabled: rgba(215, 107, 0, 1);
  --p-action-primary-hovered: rgba(215, 170, 26, 1);
  --p-action-primary-pressed: rgba(215, 184, 37, 1);
  --p-action-primary-depressed: rgba(215, 199, 49, 1);
  --p-icon-on-primary: rgba(255, 214, 153, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: rgba(215, 156, 17, 1);
  --p-text-primary-hovered: rgba(215, 173, 31, 1);
  --p-text-primary-pressed: rgba(215, 191, 44, 1);
  */

  /*
  --p-action-primary: rgba(236, 149, 11, 1);
  --p-action-primary-disabled: rgba(236, 107, 0, 1);
  --p-action-primary-hovered: rgba(236, 170, 26, 1);
  --p-action-primary-pressed: rgba(236, 184, 37, 1);
  --p-action-primary-depressed: rgba(236, 199, 49, 1);
  --p-icon-on-primary: rgba(255, 214, 153, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: rgba(236, 156, 17, 1);
  --p-text-primary-hovered: rgba(236, 173, 31, 1);
  --p-text-primary-pressed: rgba(236, 191, 44, 1);
  */

  --p-action-primary: rgba(189, 119, 8, 1);
  --p-border-highlight: var(--p-action-primary);
  --p-action-primary-disabled: var(--p-background);
  --p-action-primary-hovered: rgba(189, 136, 21, 1);
  --p-action-primary-pressed: rgba(189, 147, 28, 1);
  --p-action-primary-depressed: rgba(189, 159, 39, 1);
  --p-icon-on-primary: rgba(255, 214, 153, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: rgba(189, 124, 13, 1);
  --p-text-primary-hovered: rgba(189, 139, 25, 1);
  --p-text-primary-pressed: rgba(189, 155, 36, 1);

  --p-background: rgb(11, 12, 13);
  --p-surface: var(--p-surface-dark);
  --p-surface-neutral-subdued: var(--p-surface-neutral-subdued-dark);
  --p-surface-hovered: var(--p-surface);
  --p-surface-pressed: var(--p-surface-pressed-dark);
  --p-surface-search-field: var(--p-surface-search-field-dark);
  --p-border: var(--p-border-on-dark);
  --p-divider: var(--p-divider-dark);
  --p-border-divider: var(--p-border-divider-on-dark);
  --p-icon: var(--p-icon-on-dark);
  --p-text: var(--p-text-on-dark);
  --p-text-subdued: var(--p-text-subdued-on-dark);
  --p-interactive: var(--p-interactive-on-dark);
  --p-interactive-pressed: var(--p-interactive-pressed-on-dark);
  --p-action-secondary-hovered: var(--p-action-secondary-hovered-dark);
  --p-action-secondary-pressed: var(--p-action-secondary-pressed-dark);

  --pc-progress-bar-background: var(--p-surface-search-field-dark);
  --p-surface-neutral: var(--p-surface-search-field-dark);
  --p-surface-subdued: var(--p-surface-dark);
  --p-border-shadow: var(--p-surface-neutral-subdued-dark);
  --p-border-neutral-subdued: var(--p-surface-neutral-subdued-dark);
  --p-surface-highlight: rgba(0, 105, 113, 1);
  --p-surface-selected: var(--p-surface-neutral-subdued-dark);
  --p-surface-warning: var(--p-action-primary);
  --p-surface-success: var(--p-text-success);
  --p-surface-disabled: transparent;

  --p-backdrop: rgba(0, 0, 0, 0.9) !important;
}

.Polaris-Navigation__ItemInnerWrapper--selected,
.Polaris-Navigation__ItemInnerWrapper:hover,
.Polaris-Navigation__Item--selected:hover,
.Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused,
.Polaris-Navigation__Item:active,
.Polaris-Navigation__Item:active:hover,
.Polaris-DropZone-FileUpload__Action,
.Polaris-TextField--error
  > .Polaris-TextField__Input
  ~ .Polaris-TextField__Backdrop {
  background-color: transparent;
}

.Polaris-TextField__Backdrop,
.Polaris-Button {
  border-top-color: var(--p-border-shadow);
  border-bottom-color: var(--p-border-shadow);
}

.Polaris-Navigation__Item--selected::before {
  border-radius: var(--p-border-radius-1);
}

.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableCell:first-child,
.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  position: unset;
}

.Polaris-Banner--withinPage,
.Polaris-Banner {
  background-color: transparent;
}

.Polaris-Banner--statusInfo {
  background-color: #004f54;
}

.Polaris-Modal-CloseButton {
  display: none !important;
}
